import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const [navbarOpen, setNavbarOpen] = React.useState(false);

    return (
        <div>
            {/* ====== Navbar Section Start ====== */}
            <header className="absolute top-0 left-0 z-50 w-full meu-elemento-navbar">
                <div className="container mx-auto px-4">
                    <div className="relative flex items-center justify-between -mx-4">
                        <div className="max-w-full px-4 w-60">
                            <a href="/" className="block w-full py-5">
                                <img src="https://i.postimg.cc/25yMwKbh/Logo-Magno-Preta-1.png" alt="logo" className="block w-full dark:hidden" />
                                <img src="https://i.postimg.cc/25yMwKbh/Logo-Magno-Preta-1.png" alt="logo" className="hidden w-full dark:block" />
                            </a>
                        </div>
                        <div className="flex items-center justify-between w-full px-4">
                            <div>
                                <button
                                    onClick={() => setNavbarOpen(!navbarOpen)}
                                    className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden ${navbarOpen ? 'navbarTogglerActive' : ''}`}
                                >
                                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                                </button>
                                <nav className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white dark:bg-dark-2 py-5 px-6 shadow transition-all lg:static lg:bg-transparent lg:dark:bg-transparent lg:block lg:w-full lg:max-w-full lg:shadow-none xl:ml-11 ${!navbarOpen ? 'hidden' : ''}`}>
                                    <ul className="block lg:flex">
                                        <li>
                                            <Link to="/minecraft" className="flex py-2 text-base font-medium textopreto dark:text-white texto-principal lg:ml-10 lg:inline-flex">
                                                Minecraft
                                            </Link>
                                        </li>
                                        <li className="relative group">
                                            <a href="javascript:void(0)" className="flex py-2 text-base font-medium textopreto dark:text-white texto-principal lg:ml-10 lg:inline-flex items-center">
                                                Jogos
                                                <span className="ml-2 transform transition-transform duration-300 group-hover:rotate-180">
                                                    <i className="fa-solid fa-chevron-down"></i>
                                                </span>
                                            </a>
                                            <div className="submenu submenu-hidden transform transition-transform duration-300 ease-in-out opacity-0 group-hover:opacity-100 group-hover:translate-y-1">
                                                <a href="#" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-gamepad"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">7 days to die</p>
                                                        <p className="submenu-description">Lançamento em breve.</p>
                                                    </div>
                                                </a>
                                                <a href="#" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-gamepad"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Unturned</p>
                                                        <p className="submenu-description">Lançamento em breve.</p>
                                                    </div>
                                                </a>
                                                <a href="#" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-gamepad"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Hospedagem FiveM</p>
                                                        <p className="submenu-description">Lançamento em breve.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="relative group">
                                            <a href="javascript:void(0)" className="flex py-2 text-base font-medium textopreto dark:text-white texto-principal lg:ml-10 lg:inline-flex items-center">
                                                Servidores
                                                <span className="ml-2 transform transition-transform duration-300 group-hover:rotate-180">
                                                    <i className="fa-solid fa-chevron-down"></i>
                                                </span>
                                            </a>
                                            <div className="submenu submenu-hidden transform transition-transform duration-300 ease-in-out opacity-0 group-hover:opacity-100 group-hover:translate-y-1">
                                                <a href="/vps" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-cloud-binary"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Servidor VPS</p>
                                                        <p className="submenu-description">Um ambiente completo e isolado.</p>
                                                    </div>
                                                </a>
                                                <a href="/dedicado" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-server"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Servidor Dedicado</p>
                                                        <p className="submenu-description">Tenha a maior performance possível.</p>
                                                    </div>
                                                </a>
                                                <a href="*" className="submenu-item">
                                                    <div className="icon-background2">
                                                        <i className="fa-duotone fa-solid fa-database"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Servidores Collocation</p>
                                                        <p className="submenu-description">Seu servidor próprio alocado conosco.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="relative group">
                                            <a href="javascript:void(0)" className="flex py-2 text-base font-medium textopreto dark:text-white texto-principal lg:ml-10 lg:inline-flex items-center">
                                                Aplicações
                                                <span className="ml-2 transform transition-transform duration-300 group-hover:rotate-180">
                                                    <i className="fa-solid fa-chevron-down"></i>
                                                </span>
                                            </a>
                                            {/* Submenu */}
                                            <div className="submenu submenu-hidden transform transition-transform duration-300 ease-in-out opacity-0 group-hover:opacity-100 group-hover:translate-y-1">
                                                <a href="/bot" className="submenu-item">
                                                    <div className="icon-background">
                                                        <i className="fa-brands fa-discord"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Hospedagem BOT</p>
                                                        <p className="submenu-description">Hospede seus bots com um painel web completo.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                        <li className="relative group">
                                            <a href="javascript:void(0)" className="flex py-2 text-base font-medium textopreto dark:text-white texto-principal lg:ml-10 lg:inline-flex items-center">
                                                Outros
                                                <span className="ml-2 transform transition-transform duration-300 group-hover:rotate-180">
                                                    <i className="fa-solid fa-chevron-down"></i>
                                                </span>
                                            </a>
                                            {/* Submenu */}
                                            <div className="submenu submenu-hidden transform transition-transform duration-300 ease-in-out opacity-0 group-hover:opacity-100 group-hover:translate-y-1">
                                                <a href="https://status.magnohost.com.br/" className="submenu-item">
                                                    <div className="icon-background">
                                                        <i class="fa-solid fa-wifi"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Status</p>
                                                        <p className="submenu-description">Acompanhe o status de toda nossa infraestrutura.</p>
                                                    </div>
                                                </a>
                                                <a href="/termos" className="submenu-item">
                                                    <div className="icon-background">
                                                        <i class="fa-duotone fa-solid fa-handshake"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Termos de Serviço</p>
                                                        <p className="submenu-description">Fique ciente de todos os nossos termos.</p>
                                                    </div>
                                                </a>
                                                <a href="https://financeiro.magnohost.com.br/knowledgebase" className="submenu-item">
                                                    <div className="icon-background">
                                                        <i class="fa-duotone fa-solid fa-book"></i>
                                                    </div>
                                                    <div>
                                                        <p className="submenu-title">Base de conhecimento</p>
                                                        <p className="submenu-description">Precisa de ajuda? Venho ao lugar certo.</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="justify-end hidden pr-16 sm:flex lg:pr-0">
                                <a href="https://painel.magnohost.com.br/" className="py-3 text-base font-medium px-7 textopreto dark:text-white texto-principal">
                                    Entrar
                                </a>
                                <a href="https://financeiro.magnohost.com.br/" className="py-3 text-base font-medium text-white rounded-md px-7 btn-primario">
                                    Área do cliente
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
