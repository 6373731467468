import React from 'react';
import Footer from './components/index/Footer.jsx';
import Bot from './components/bot/BotBlock.jsx';
import Teste from './components/bot/TestFree.jsx';


const BotPage = () => {
  return (
    <div>
      <Bot />
      <Teste />
      <Footer />
    </div>
  );
};

export default BotPage;
