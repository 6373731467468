import React from 'react';

const Footer = () => {
  return (
    <footer className="relative z-10 bg-white dark:bg-dark pt-20 pb-10 lg:pt-[120px] lg:pb-20 meu-elemento-footer">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 sm:w-2/3 lg:w-3/12">
            <div className="w-full mb-10">
              <a href="javascript:void(0)" className="mb-6 inline-block max-w-[160px]">
                <img
                  src="https://i.postimg.cc/25yMwKbh/Logo-Magno-Preta-1.png"
                  alt="logo"
                  className="max-w-full dark:hidden"
                />
                <img src="./assets/images/logo-white.svg" alt="logo" className="max-w-full hidden dark:block" />
              </a>
              <p className="text-base text-body-color dark:text-dark-6 mb-7">
                Nós nos esforçamos para fornecer a melhor experiência de hospedagem em todos os nossos serviços.
                Entre em contato conosco para qualquer suporte ou dúvida.
              </p>
              <div className="flex items-center mb-6">
                <a
                  href="javascript:void(0)"
                  className="flex items-center justify-center w-8 h-8 mr-3 border rounded-full text-dark hover:border-primary hover:bg-primary border-stroke dark:border-dark-3 dark:hover:border-primary dark:text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
                >
                  <svg width="16" height="12" viewBox="0 0 16 12" className="fill-current">
                    <path
                      d="M14.2194 2.06654L15.2 0.939335C15.4839 0.634051 15.5613 0.399217 15.5871 0.2818C14.8129 0.704501 14.0903 0.845401 13.6258 0.845401H13.4452L13.3419 0.751468C12.7226 0.258317 11.9484 0 11.1226 0C9.31613 0 7.89677 1.36204 7.89677 2.93542C7.89677 3.02935 7.89677 3.17025 7.92258 3.26419L8 3.73386L7.45806 3.71037C4.15484 3.61644 1.44516 1.03327 1.00645 0.587084C0.283871 1.76125 0.696774 2.88845 1.13548 3.59296L2.0129 4.90802L0.619355 4.20352C0.645161 5.18982 1.05806 5.96477 1.85806 6.52838L2.55484 6.99804L1.85806 7.25636C2.29677 8.45401 3.27742 8.94716 4 9.13503L4.95484 9.36986L4.05161 9.93346C2.60645 10.8728 0.8 10.8024 0 10.7319C1.62581 11.7652 3.56129 12 4.90323 12C5.90968 12 6.65806 11.9061 6.83871 11.8356C14.0645 10.2857 14.4 4.41487 14.4 3.2407V3.07632L14.5548 2.98239C15.4323 2.23092 15.7935 1.8317 16 1.59687C15.9226 1.62035 15.8194 1.66732 15.7161 1.6908L14.2194 2.06654Z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
            <div className="w-full mb-10">
              <h4 className="text-lg font-semibold text-dark dark:text-white mb-9">Serviços</h4>
              <ul className="space-y-3">
                <li>
                  <a
                    href="/minecraft"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Host Minecraft
                  </a>
                </li>
                <li>
                  <a
                    href="/vps"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Servidores VPS
                  </a>
                </li>
                <li>
                  <a
                    href="/bot"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Hospedagem Bots
                  </a>
                </li>
                <li>
                  <a
                    href="/dedicado"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Servidores baremetal
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
            <div className="w-full mb-10">
              <h4 className="text-lg font-semibold text-dark dark:text-white mb-9">Páginas</h4>
              <ul className="space-y-3">
                <li>
                  <a
                    href="status.magnohost.com.br"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Status
                  </a>
                </li>
                <li>
                  <a
                    href="/termos"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Termos de uso
                  </a>
                </li>
                <li>
                  <a
                    href="https://br.trustpilot.com/review/magnohost.com.br"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Trust Pilot
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.reclameaqui.com.br/empresa/magnohost/"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Reclame Aqui
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
            <div className="w-full mb-10">
              <h4 className="text-lg font-semibold text-dark dark:text-white mb-9">Entre em contato</h4>
              <ul className="space-y-3">
                <li>
                  <a
                    href="https://discord.gg/3T3tc5ehrX"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Discord
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    Chat do site
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    className="inline-block text-base leading-loose text-body-color texto-principal dark:text-dark-6"
                  >
                    +55 45 99105-1998
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
            <div className="w-full mb-10">
              <h4 className="text-lg font-semibold text-dark dark:text-white mb-9">Informações</h4>

              <p className="text-base text-body-color dark:text-dark-6">
                © 2024 MagnoHost <br /> CNPJ: 53.000.870/0001-27
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className="absolute left-0 bottom-0 z-[-1]">
          <svg width="217" height="229" viewBox="0 0 217 229" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
              fill="url(#paint0_linear_1179_5)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_1179_5"
                x1="76.5"
                y1="281"
                x2="76.5"
                y2="1.22829e-05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7a0cf3" stopOpacity="0.08"></stop>
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute top-10 right-10 z-[-1]">
          <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
              fill="url(#paint0_linear_1179_4)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_1179_4"
                x1="-1.63917e-06"
                y1="37.5"
                x2="75"
                y2="37.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7a0cf3" stopOpacity="0.31"></stop>
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
