import React, { useState } from 'react';

const FAQSection = () => {
  const [openFaq, setOpenFaq] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
    faq6: false,
  });

  const toggleFaq = (faqKey) => {
    setOpenFaq((prevState) => ({
      ...prevState,
      [faqKey]: !prevState[faqKey],
    }));
  };

  return (
    <section className="relative z-20 overflow-hidden bg-white dark:bg-dark pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] meu-elemento-faq">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">
              <span className="block mb-2 text-lg font-semibold" style={{ color: '#7a0cf3' }}>
                Perguntas Frequentes
              </span>
              <h2 className="text-dark dark:text-white mb-4 text-3xl font-bold sm:text-[40px]/[48px]">
                Alguma dúvida? Olhe aqui
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 lg:w-1/2">
            <div className="w-full p-4 mb-8 bg-white rounded-lg shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] dark:bg-dark-2 sm:p-8 lg:px-6 xl:px-8">
              <button className="flex w-full text-left faq-btn" onClick={() => toggleFaq('faq1')}>
                <div className="bg-primary/5 dark:bg-white/5 text-primary mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg">
                  <svg className={openFaq.faq1 ? 'rotate-180' : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 15.675C10.7937 15.675 10.6219 15.6062 10.45 15.4687L2.54374 7.69998C2.23436 7.3906 2.23436 6.90935 2.54374 6.59998C2.85311 6.2906 3.33436 6.2906 3.64374 6.59998L11 13.7844L18.3562 6.53123C18.6656 6.22185 19.1469 6.22185 19.4562 6.53123C19.7656 6.8406 19.7656 7.32185 19.4562 7.63123L11.55 15.4C11.3781 15.5719 11.2062 15.675 11 15.675Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">Quais os meios de pagamentos suportados?</h4>
                </div>
              </button>
              {openFaq.faq1 && (
                <div className="faq-content pl-[62px]">
                  <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                    Aceitamos diversos meios de pagamento como cartão de crédito/débito, boleto, pix e outros, consulte todas as formas de pagamento ao
                    finalizar uma compra em nossa área do cliente.
                  </p>
                </div>
              )}
            </div>

            <div className="w-full p-4 mb-8 bg-white rounded-lg shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] dark:bg-dark-2 sm:p-8 lg:px-6 xl:px-8">
              <button className="flex w-full text-left faq-btn" onClick={() => toggleFaq('faq2')}>
                <div className="bg-primary/5 dark:bg-white/5 text-primary mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg">
                  <svg className={openFaq.faq2 ? 'rotate-180' : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 15.675C10.7937 15.675 10.6219 15.6062 10.45 15.4687L2.54374 7.69998C2.23436 7.3906 2.23436 6.90935 2.54374 6.59998C2.85311 6.2906 3.33436 6.2906 3.64374 6.59998L11 13.7844L18.3562 6.53123C18.6656 6.22185 19.1469 6.22185 19.4562 6.53123C19.7656 6.8406 19.7656 7.32185 19.4562 7.63123L11.55 15.4C11.3781 15.5719 11.2062 15.675 11 15.675Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">Posso hospedar um serviço que não está no site?</h4>
                </div>
              </button>
              {openFaq.faq2 && (
                <div className="faq-content pl-[62px]">
                  <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                  Se você precisar de uma solução personalizada para seu projeto, entre em contato conosco por meio dos nossos canais de atendimento.
                  Analisaremos sua solicitação com cuidado e verificaremos a viabilidade de fornecer uma solução sob medida.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="w-full px-4 lg:w-1/2">
            <div className="w-full p-4 mb-8 bg-white rounded-lg shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] dark:bg-dark-2 sm:p-8 lg:px-6 xl:px-8">
              <button className="flex w-full text-left faq-btn" onClick={() => toggleFaq('faq4')}>
                <div className="bg-primary/5 dark:bg-white/5 text-primary mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg">
                  <svg className={openFaq.faq4 ? 'rotate-180' : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 15.675C10.7937 15.675 10.6219 15.6062 10.45 15.4687L2.54374 7.69998C2.23436 7.3906 2.23436 6.90935 2.54374 6.59998C2.85311 6.2906 3.33436 6.2906 3.64374 6.59998L11 13.7844L18.3562 6.53123C18.6656 6.22185 19.1469 6.22185 19.4562 6.53123C19.7656 6.8406 19.7656 7.32185 19.4562 7.63123L11.55 15.4C11.3781 15.5719 11.2062 15.675 11 15.675Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">Posso ter reembolso de um serviço já contratado?</h4>
                </div>
              </button>
              {openFaq.faq4 && (
                <div className="faq-content pl-[62px]">
                  <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                    Para reembolsos existe um prazo legal de cancelamento de até 7 dias após a contratação de um de nossos serviços, como estipulado no Art.
                    49 do Código de defesa do consumidor. Abra um ticket informando todos os dados para prosseguir com o reembolso.
                  </p>
                </div>
              )}
            </div>

            <div className="w-full p-4 mb-8 bg-white rounded-lg shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] dark:bg-dark-2 sm:p-8 lg:px-6 xl:px-8">
              <button className="flex w-full text-left faq-btn" onClick={() => toggleFaq('faq5')}>
                <div className="bg-primary/5 dark:bg-white/5 text-primary mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg">
                  <svg className={openFaq.faq5 ? 'rotate-180' : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 15.675C10.7937 15.675 10.6219 15.6062 10.45 15.4687L2.54374 7.69998C2.23436 7.3906 2.23436 6.90935 2.54374 6.59998C2.85311 6.2906 3.33436 6.2906 3.64374 6.59998L11 13.7844L18.3562 6.53123C18.6656 6.22185 19.1469 6.22185 19.4562 6.53123C19.7656 6.8406 19.7656 7.32185 19.4562 7.63123L11.55 15.4C11.3781 15.5719 11.2062 15.675 11 15.675Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="mt-1 text-lg font-semibold text-dark dark:text-white">Quais os objetivos da MagnoHost?</h4>
                </div>
              </button>
              {openFaq.faq5 && (
                <div className="faq-content pl-[62px]">
                  <p className="py-3 text-base leading-relaxed text-body-color dark:text-dark-6">
                  Nossa empresa de hospedagem entrou no mercado com o objetivo de oferecer desde serviços de hospedagem de jogos até servidores dedicados, priorizando sempre a confiança e a qualidade.
                  Com uma abordagem centrada no cliente, buscamos entregar soluções robustas e seguras para todos os projetos.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
