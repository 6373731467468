import React from 'react';

const AboutSectionMINE = () => {
    return (
        <>
            <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark meu-elemento-about">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap items-center justify-center -mx-4">
                        <div className="w-full px-4 lg:w-6/12">
                            <div className="flex items-center -mx-3 sm:-mx-4">
                                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                    <div className="relative z-10 my-4">
                                        <img src="https://i.imgur.com/2xCvcOo.png" style={{width: "330px", height: "300px", transform: "scale(1.5)"}}  className="w-full rounded-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                            <div className="mt-10 lg:mt-0">
                                <span
                                    className="block mb-4 text-lg font-semibold"
                                    style={{ color: '#7a0cf3', backgroundColor: '#e5cdff75', padding: '2px 25px', width: '278px', borderRadius: '35px' }}
                                >
                                    Painel de gerenciamento
                                </span>
                                <h2 className="mb-5 text-3xl font-bold textopreto dark:text-white sm:text-[40px]/[48px]">
                                    Melhor painel para gerenciar seu servidor
                                </h2>
                                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                                    Para o gerenciamento do seu servidor de Minecraft, utilizamos o painel Pterodactyl, uma solução poderosa e intuitiva. Com sua interface amigável, o Pterodactyl permite que qualquer usuário, mesmo sem conhecimento prévio, gerencie seu servidor de forma eficiente e sem dificuldades.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark meu-elemento-about">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap items-center justify-center -mx-4">
                        {/* Text Section */}
                        <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                            <div className="mt-10 lg:mt-0">
                                <span
                                    className="block mb-4 text-lg font-semibold"
                                    style={{ color: '#7a0cf3', backgroundColor: '#e5cdff75', padding: '2px 25px', width: '350px', borderRadius: '35px' }}
                                >
                                    Comece rapidamente a utilizar
                                </span>
                                <h2 className="mb-5 text-3xl font-bold textopreto dark:text-white sm:text-[40px]/[48px]">
                                    Entrega rápida após pagamento
                                </h2>
                                <p className="mb-5 text-base text-body-color dark:text-dark-6">
                                    Todos os serviços não dedicados são disponibilizados instantaneamente através do nosso sistema, quando adquiridos por cartão de crédito, débito ou Pix. Para outros métodos de pagamento, como boleto bancário, o processo de aprovação pode levar até 3 dias úteis.
                                </p>
                            </div>
                        </div>
                        {/* Image Section */}
                        <div className="w-full px-4 lg:w-6/12">
                            <div className="flex items-center justify-center -mx-3 sm:-mx-4">
                                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                                    <div className="relative z-10 my-4">
                                        <img src="https://i.imgur.com/gCVkh4t.png" style={{width: "330px", height: "300px", transform: "scale(1.5)"}} className="w-full rounded-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutSectionMINE;
