import React from 'react';
import Footer from './components/index/Footer.jsx'; 
import TermosSection from './components/terms/TermsBlock.jsx';
import HeroSection from './components/index/HeroSection.jsx';


const Termos = () => {
  return (
    <div>
      <TermosSection />
      <Footer />
    </div>
  );
};

export default Termos;
