import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/index/Navbar'; 
import MinecraftPage from './MinecraftPage.jsx'; 
import HomePage from './App.jsx';
import BotPage from './BotPage.jsx';
import Dedicado from './DedicadoPagina.jsx';
import VPSPage from './VPSPage.jsx';
import TermosPage from './Termos.jsx';
import PageNotFound from './404'; 

const AppRoutes = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/minecraft" element={<MinecraftPage />} />
        <Route path="/termos" element={<TermosPage />} />
        <Route path="/bot" element={<BotPage />} />
        <Route path="/vps" element={<VPSPage />} />
        <Route path="/dedicado" element={<Dedicado />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
