import React from "react";

const test = () => {
    return (
        <>
            <section style={{marginTop: "-80px"}}
                className="py-20 lg:py-[30px] bg-white dark:bg-dark"
                data-id="5a436708-59e9-42ef-9"
            >
                <div className="container mx-auto" data-id="fd8a5bf5-f5c2-43b3-8">
                <div className="-mx-4 flex flex-wrap" data-id="ad1d9fc2-aa91-4e61-a">
                        <div className="w-full px-4 selected" data-id="5d819f80-c039-4377-a">
                            <div
                                className="mx-auto mb-[60px] max-w-[510px] text-center"
                                data-id="5780492e-9c37-4fc3-8"
                            >
                                <span
                                    className="mb-2 block text-lg font-semibold text-primary"
                                    content-id="0d5991b5-e73b-459e-9"
                                    data-id="5d3621cf-4eb1-45a2-b"
                                >
                                    Utilize gratuitamente o serviço
                                </span>
                                <h2
                                    className="mb-3 text-3xl leading-[1.208] font-bold textopreto sm:text-4xl md:text-[40px]"
                                    content-id="fbe17dee-4bb0-4f20-9"
                                    data-id="4f4da58c-adec-4698-b"
                                >
                                    Resgate agora mesmo um plano vitalício e tenha ele para sempre!
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div
                        className="relative z-10 overflow-hidden rounded bg-primary py-12 px-8 md:p-[70px]"
                        data-id="3c3891ab-1b7a-40c1-a"
                        style={{ backgroundColor: "#7a0cf3", borderRadius: 25 }}
                    >
                        <div
                            className="flex flex-wrap items-center -mx-4"
                            data-id="3e7eb083-ee60-4f6e-b"
                        >
                            <div className="w-full px-4 lg:w-1/2" data-id="707467b2-69ec-4c83-9">
                                <span
                                    className="block mb-4 text-base font-medium text-white"
                                    content-id="b1c5e435-8907-49a6-a"
                                    data-id="f977d189-7a12-415f-8"
                                    style={{ marginBottom: 2 }}
                                ></span>
                                <h2
                                    className="mb-6 text-3xl font-bold leading-tight text-white sm:mb-8 sm:text-[40px]/[48px] lg:mb-0"
                                    content-id="8becdbef-da89-4f91-9"
                                    data-id="9c1d8661-d2c8-46a0-8"
                                >
                                    <span
                                        className="xs:block"
                                        content-id="6268fd9e-972e-4ea4-b"
                                        data-id="159f2fbb-6be7-498f-b"
                                    >
                                        {" "}
                                        Clique para{" "}
                                    </span>
                                    <span content-id="2571a5b1-68ea-4018-8" data-id="ce6fcb63-e623-4658-9">
                                        resgatar seu{" "}
                                        <span
                                            style={{
                                                backgroundColor: "#6000c9",
                                                padding: "5px 8px",
                                                borderRadius: 15,
                                                textAlign: "center"
                                            }}
                                        >
                                            Servidor!
                                        </span>
                                    </span>
                                </h2>
                            </div>
                            <div className="w-full px-4 lg:w-1/2" data-id="6166787a-703b-4a09-9">
                                <div
                                    className="flex flex-wrap lg:justify-end"
                                    data-id="4db46096-7cc4-412f-b"
                                >
                                    <a
                                        href="https://financeiro.magnohost.com.br/cart.php?a=add&pid=24&promocode=FREE"
                                        className="inline-flex py-3 my-1 text-base font-medium text-white transition rounded-md btn-primario2 px-7 hover:bg-opacity-90"
                                        content-id="5f58acf9-1b64-4024-9"
                                        data-id="5b993d44-d510-4903-9"
                                    >
                                        Resgatar agora
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div data-id="4896b4f1-18e0-43cf-b">
                            <span
                                className="absolute top-0 left-0 z-[-1]"
                                content-id="3e5a5c72-07dd-402e-a"
                                data-id="f486fd14-d314-4c7e-a"
                            >
                                <svg
                                    width={189}
                                    height={162}
                                    viewBox="0 0 189 162"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-id="0f12f17b-ef93-428a-9"
                                >
                                    <ellipse
                                        cx={16}
                                        cy="-16.5"
                                        rx={173}
                                        ry="178.5"
                                        transform="rotate(180 16 -16.5)"
                                        fill="url(#paint0_linear)"
                                        content-id="b0977809-904c-465c-8"
                                        data-id="dd5f4c9f-99c8-4baa-b"
                                    />
                                    <defs data-id="3e7281e2-2ee4-4dd4-9">
                                        <linearGradient
                                            id="paint0_linear"
                                            x1={-157}
                                            y1="-107.754"
                                            x2="98.5011"
                                            y2="-106.425"
                                            gradientUnits="userSpaceOnUse"
                                            data-id="040275e5-f8f7-419f-b"
                                        >
                                            <stop
                                                stopColor="white"
                                                stopOpacity="0.07"
                                                content-id="1b8640f8-ed3b-4f4c-a"
                                                data-id="42a3ef8e-27d4-4e4f-9"
                                            />
                                            <stop
                                                offset={1}
                                                stopColor="white"
                                                stopOpacity={0}
                                                content-id="248da50c-7c2d-4d61-9"
                                                data-id="8126a711-d5e6-46fe-9"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                            <span
                                className="absolute bottom-0 right-0 z-[-1]"
                                content-id="4260063b-1a1c-4b4f-8"
                                data-id="6cf6a613-6c86-4b54-a"
                            >
                                <svg
                                    width={191}
                                    height={208}
                                    viewBox="0 0 191 208"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-id="8418563f-99ae-4e76-8"
                                >
                                    <ellipse
                                        cx={173}
                                        cy="178.5"
                                        rx={173}
                                        ry="178.5"
                                        fill="url(#paint0_linear)"
                                        content-id="a58af271-5320-49cc-a"
                                        data-id="ce37159a-95f2-46b6-b"
                                    />
                                    <defs data-id="2425a605-40f5-4d47-9">
                                        <linearGradient
                                            id="paint0_linear"
                                            x1="-3.27832e-05"
                                            y1="87.2457"
                                            x2="255.501"
                                            y2="88.5747"
                                            gradientUnits="userSpaceOnUse"
                                            data-id="94be88d5-9858-408f-a"
                                        >
                                            <stop
                                                stopColor="white"
                                                stopOpacity="0.07"
                                                content-id="bc32756d-4dff-44b2-a"
                                                data-id="779028f2-658c-4eb0-a"
                                            />
                                            <stop
                                                offset={1}
                                                stopColor="white"
                                                stopOpacity={0}
                                                content-id="84b0aad2-96f6-420a-b"
                                                data-id="2d161c62-b55b-44c1-8"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default test;