import React from 'react';

const BotBlock = () => {
    return (
        <>
            <section
                className="relative z-10 overflow-hidden bg-white dark:bg-dark pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
                data-id="10883e95-a419-44f1-8"
                style={{ marginTop: 120 }}
            >
                <div className="container mx-auto" data-id="a5a99372-1aee-4984-8">
                    <div className="-mx-4 flex flex-wrap" data-id="ad1d9fc2-aa91-4e61-a">
                        <div className="w-full px-4 selected" data-id="5d819f80-c039-4377-a">
                            <div
                                className="mx-auto mb-[60px] max-w-[510px] text-center"
                                data-id="5780492e-9c37-4fc3-8"
                            >
                                <span
                                    className="mb-2 block text-lg font-semibold text-primary"
                                    content-id="0d5991b5-e73b-459e-9"
                                    data-id="5d3621cf-4eb1-45a2-b"
                                >
                                    Escolha a sua Hospedagem de Bot
                                </span>
                                <h2
                                    className="mb-3 text-3xl leading-[1.208] font-bold textopreto sm:text-4xl md:text-[40px]"
                                    content-id="fbe17dee-4bb0-4f20-9"
                                    data-id="4f4da58c-adec-4698-b"
                                >
                                    Hospedagem de BOT
                                </h2>
                                <p
                                    className="text-base text-body-color dark:text-dark-6"
                                    content-id="dd613355-b4f8-47fa-a"
                                    data-id="165ba753-a88a-4fd7-b"
                                >
                                    Experimente a qualidade que a MagnoHost pode te oferecer em
                                    hospedagem de bots e se surpreenda!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="-mx-4 flex flex-wrap justify-center"
                        data-id="0da63a3f-90cc-4111-8"
                    >
                        <div
                            className="w-full px-4 md:w-1/2 lg:w-1/3"
                            data-id="50b55f1b-21af-4ec6-a"
                        >
                            <div
                                className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke dark:border-dark-3 bg-white dark:bg-dark-2 py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-[50px]"
                                data-id="f89d05ec-0bb4-49be-9"
                            >
                                <span
                                    className="mb-3 block text-lg font-semibold text-primary"
                                    content-id="9263ca3d-6f87-461a-9"
                                    data-id="62f3d551-ee54-46c4-b"
                                >
                                    BOT US 1
                                </span>
                                <h2
                                    className="mb-5 text-[42px] font-bold text-dark dark:text-white"
                                    content-id="dece4afa-5efe-4cc1-9"
                                    data-id="8540def9-d920-4979-9"
                                >
                                    <span className='textopreto'
                                        content-id="b3d14852-8d38-4fd1-9"
                                        data-id="2a29a48d-af3f-45fb-a"
                                    >
                                        R$3,99
                                    </span>
                                    <span
                                        className="text-base font-medium text-body-color dark:text-dark-6"
                                        content-id="0cc6019c-4a47-4839-b"
                                        data-id="7577e45d-2555-4028-b"
                                    >
                                        / por mês
                                    </span>
                                </h2>
                                <div
                                    className="mb-9 flex flex-col gap-[14px]"
                                    data-id="b0cba0ca-56af-4e65-8"
                                >
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="48698a29-d522-49f6-a"
                                        data-id="4e3251d0-70ab-4387-8"
                                    >
                                        AMD Ryzen 9 7950x
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="bd18de4a-3c5c-45b4-a"
                                        data-id="ad8856c5-f2e9-405b-9"
                                    >
                                        1GB RAM DDR5
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="5842d0ed-957a-46b6-b"
                                        data-id="15ffa95e-53a5-4b24-a"
                                    >
                                        0.50vCPU [5.6Ghz]
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="703c0339-5864-4381-8"
                                        data-id="2fa1f284-4005-46b2-9"
                                    >
                                        10GB SSD NVME
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="6df05423-84ce-4025-9"
                                        data-id="6129a517-526d-4d58-9"
                                    >
                                        Até 2 projetos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Proteção Anti-DDoS
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Backups inclusos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Suporte a JS e Python
                                    </p>
                                </div>
                                <a
                                    href="https://financeiro.magnohost.com.br/store/bot-discord/bot-discord-01"
                                    className="block w-full rounded-md btn-primario p-3 text-center text-base font-medium transition"
                                    content-id="24f2a98a-f0fe-417b-8"
                                    data-id="46e8a790-075d-4037-a"
                                >
                                    Contratar Agora
                                </a>
                                <div data-id="a06351a3-dabb-42b2-9">
                                    <span
                                        className="absolute right-0 top-7 z-[-1]"
                                        content-id="ed76c6d6-1a60-4267-9"
                                        data-id="86a449e7-270c-4bde-a"
                                    >
                                        <svg
                                            width={77}
                                            height={172}
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-id="8db699bd-1047-4dcb-b"
                                        >
                                            <circle
                                                cx={86}
                                                cy={86}
                                                r={86}
                                                fill="url(#paint0_linear)"
                                                content-id="79c8a82e-c35c-48a7-b"
                                                data-id="5aea71e6-f2c7-40dc-9"
                                            />
                                            <defs data-id="48cd3f30-62f6-4f34-a">
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1={86}
                                                    y1={0}
                                                    x2={86}
                                                    y2={172}
                                                    gradientUnits="userSpaceOnUse"
                                                    data-id="ad8624b2-7e83-45b5-b"
                                                >
                                                    <stop
                                                        stopColor="#3056D3"
                                                        stopOpacity="0.09"
                                                        content-id="43837f8c-2932-414f-b"
                                                        data-id="b5359a1e-3364-4002-b"
                                                    />
                                                    <stop
                                                        offset={1}
                                                        stopColor="#C4C4C4"
                                                        stopOpacity={0}
                                                        content-id="74d8f604-3b05-4cce-8"
                                                        data-id="93c68945-8804-4d95-8"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full px-4 md:w-1/2 lg:w-1/3"
                            data-id="50b55f1b-21af-4ec6-a"
                        >
                            <div
                                className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke dark:border-dark-3 bg-white dark:bg-dark-2 py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-[50px]"
                                data-id="f89d05ec-0bb4-49be-9"
                            >
                                <span
                                    className="mb-3 block text-lg font-semibold text-primary"
                                    content-id="9263ca3d-6f87-461a-9"
                                    data-id="62f3d551-ee54-46c4-b"
                                >
                                    BOT US 2
                                </span>
                                <h2
                                    className="mb-5 text-[42px] font-bold text-dark dark:text-white"
                                    content-id="dece4afa-5efe-4cc1-9"
                                    data-id="8540def9-d920-4979-9"
                                >
                                    <span className='textopreto'
                                        content-id="b3d14852-8d38-4fd1-9"
                                        data-id="2a29a48d-af3f-45fb-a"
                                    >
                                        R$7,99
                                    </span>
                                    <span
                                        className="text-base font-medium text-body-color dark:text-dark-6"
                                        content-id="0cc6019c-4a47-4839-b"
                                        data-id="7577e45d-2555-4028-b"
                                    >
                                        / por mês
                                    </span>
                                </h2>
                                <div
                                    className="mb-9 flex flex-col gap-[14px]"
                                    data-id="b0cba0ca-56af-4e65-8"
                                >
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="48698a29-d522-49f6-a"
                                        data-id="4e3251d0-70ab-4387-8"
                                    >
                                        AMD Ryzen 9 7950x
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="bd18de4a-3c5c-45b4-a"
                                        data-id="ad8856c5-f2e9-405b-9"
                                    >
                                        2GB RAM DDR5
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="5842d0ed-957a-46b6-b"
                                        data-id="15ffa95e-53a5-4b24-a"
                                    >
                                        1vCPU [5.6Ghz]
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="703c0339-5864-4381-8"
                                        data-id="2fa1f284-4005-46b2-9"
                                    >
                                        20GB SSD NVME
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="6df05423-84ce-4025-9"
                                        data-id="6129a517-526d-4d58-9"
                                    >
                                        Até 4 projetos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Proteção Anti-DDoS
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Backups inclusos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Suporte a JS e Python
                                    </p>
                                </div>
                                <a
                                    href="https://financeiro.magnohost.com.br/store/bot-discord/bot-discord-02"
                                    className="block w-full rounded-md btn-primario p-3 text-center text-base font-medium transition"
                                    content-id="24f2a98a-f0fe-417b-8"
                                    data-id="46e8a790-075d-4037-a"
                                >
                                    Contratar Agora
                                </a>
                                <div data-id="a06351a3-dabb-42b2-9">
                                    <span
                                        className="absolute right-0 top-7 z-[-1]"
                                        content-id="ed76c6d6-1a60-4267-9"
                                        data-id="86a449e7-270c-4bde-a"
                                    >
                                        <svg
                                            width={77}
                                            height={172}
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-id="8db699bd-1047-4dcb-b"
                                        >
                                            <circle
                                                cx={86}
                                                cy={86}
                                                r={86}
                                                fill="url(#paint0_linear)"
                                                content-id="79c8a82e-c35c-48a7-b"
                                                data-id="5aea71e6-f2c7-40dc-9"
                                            />
                                            <defs data-id="48cd3f30-62f6-4f34-a">
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1={86}
                                                    y1={0}
                                                    x2={86}
                                                    y2={172}
                                                    gradientUnits="userSpaceOnUse"
                                                    data-id="ad8624b2-7e83-45b5-b"
                                                >
                                                    <stop
                                                        stopColor="#3056D3"
                                                        stopOpacity="0.09"
                                                        content-id="43837f8c-2932-414f-b"
                                                        data-id="b5359a1e-3364-4002-b"
                                                    />
                                                    <stop
                                                        offset={1}
                                                        stopColor="#C4C4C4"
                                                        stopOpacity={0}
                                                        content-id="74d8f604-3b05-4cce-8"
                                                        data-id="93c68945-8804-4d95-8"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full px-4 md:w-1/2 lg:w-1/3"
                            data-id="50b55f1b-21af-4ec6-a"
                        >
                            <div
                                className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke dark:border-dark-3 bg-white dark:bg-dark-2 py-10 px-8 shadow-pricing sm:p-12 lg:py-10 lg:px-6 xl:p-[50px]"
                                data-id="f89d05ec-0bb4-49be-9"
                            >
                                <span
                                    className="mb-3 block text-lg font-semibold text-primary"
                                    content-id="9263ca3d-6f87-461a-9"
                                    data-id="62f3d551-ee54-46c4-b"
                                >
                                    BOT US 3
                                </span>
                                <h2
                                    className="mb-5 text-[42px] font-bold text-dark dark:text-white"
                                    content-id="dece4afa-5efe-4cc1-9"
                                    data-id="8540def9-d920-4979-9"
                                >
                                    <span className='textopreto'
                                        content-id="b3d14852-8d38-4fd1-9"
                                        data-id="2a29a48d-af3f-45fb-a"
                                    >
                                        R$15,99
                                    </span>
                                    <span
                                        className="text-base font-medium text-body-color dark:text-dark-6"
                                        content-id="0cc6019c-4a47-4839-b"
                                        data-id="7577e45d-2555-4028-b"
                                    >
                                        / por mês
                                    </span>
                                </h2>
                                <div
                                    className="mb-9 flex flex-col gap-[14px]"
                                    data-id="b0cba0ca-56af-4e65-8"
                                >
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="48698a29-d522-49f6-a"
                                        data-id="4e3251d0-70ab-4387-8"
                                    >
                                        AMD Ryzen 9 7950x
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="bd18de4a-3c5c-45b4-a"
                                        data-id="ad8856c5-f2e9-405b-9"
                                    >
                                        4GB RAM DDR5
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="5842d0ed-957a-46b6-b"
                                        data-id="15ffa95e-53a5-4b24-a"
                                    >
                                        2vCPU [5.6Ghz]
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="703c0339-5864-4381-8"
                                        data-id="2fa1f284-4005-46b2-9"
                                    >
                                        30GB SSD NVME
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="6df05423-84ce-4025-9"
                                        data-id="6129a517-526d-4d58-9"
                                    >
                                        Até 8 projetos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Proteção Anti-DDoS
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Backups inclusos
                                    </p>
                                    <p
                                        className="text-base text-body-color dark:text-dark-6"
                                        content-id="76fe32ce-16b6-4ccd-9"
                                        data-id="d0544aab-91d9-4cb5-8"
                                    >
                                        Suporte a JS e Python
                                    </p>
                                </div>
                                <a
                                    href="https://financeiro.magnohost.com.br/store/bot-discord/bot-discord-03"
                                    className="block w-full rounded-md btn-primario p-3 text-center text-base font-medium transition"
                                    content-id="24f2a98a-f0fe-417b-8"
                                    data-id="46e8a790-075d-4037-a"
                                >
                                    Contratar Agora
                                </a>
                                <div data-id="a06351a3-dabb-42b2-9">
                                    <span
                                        className="absolute right-0 top-7 z-[-1]"
                                        content-id="ed76c6d6-1a60-4267-9"
                                        data-id="86a449e7-270c-4bde-a"
                                    >
                                        <svg
                                            width={77}
                                            height={172}
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-id="8db699bd-1047-4dcb-b"
                                        >
                                            <circle
                                                cx={86}
                                                cy={86}
                                                r={86}
                                                fill="url(#paint0_linear)"
                                                content-id="79c8a82e-c35c-48a7-b"
                                                data-id="5aea71e6-f2c7-40dc-9"
                                            />
                                            <defs data-id="48cd3f30-62f6-4f34-a">
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1={86}
                                                    y1={0}
                                                    x2={86}
                                                    y2={172}
                                                    gradientUnits="userSpaceOnUse"
                                                    data-id="ad8624b2-7e83-45b5-b"
                                                >
                                                    <stop
                                                        stopColor="#3056D3"
                                                        stopOpacity="0.09"
                                                        content-id="43837f8c-2932-414f-b"
                                                        data-id="b5359a1e-3364-4002-b"
                                                    />
                                                    <stop
                                                        offset={1}
                                                        stopColor="#C4C4C4"
                                                        stopOpacity={0}
                                                        content-id="74d8f604-3b05-4cce-8"
                                                        data-id="93c68945-8804-4d95-8"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default BotBlock;
