import React from 'react';

const ServiceSection = () => {
  return (
    <section
      className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark meu-elemento-services"
      style={{ backgroundColor: '#7a0cf3' }}
    >
      <div className="container mx-auto px-4">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mb-12 max-w-[510px] text-left lg:mb-20">
              <h2
                className="text-dark dark:text-white mb-3 text-3xl leading-[1.2] font-bold sm:text-4xl md:text-[40px]"
                style={{ color: 'white' }}
              >
                <span style={{ fontWeight: 600 }}>|</span> Tudo o que você deseja em um só lugar!
              </h2>
            </div>
          </div>
        </div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-shield-check fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
                Anti-DDos de ponta
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Utilizamos a proteção anti-DDoS Magic Transit inline, garantindo segurança e alta disponibilidade dos nossos serviços.
              Com essa solução, mitigamos ataques cibernéticos em tempo real, mantendo a confiança e a estabilidade que nossos clientes esperam.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-building-shield fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
                Data center Tier III
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Nossa empresa conta com uma infraestrutura localizada no data center Ascenty em São Paulo, garantindo alta disponibilidade e segurança. Com essa base sólida, oferecemos serviços confiáveis e de alta performance para nossos clientes.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-server fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
                Infraestrutura própria
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Nossa empresa utiliza hardware próprio para todos os servidores dedicados, garantindo total controle e desempenho otimizado. Com essa abordagem, oferecemos soluções personalizadas e de alta confiabilidade para nossos clientes.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-money-bill fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
              Planos Flexíveis
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Nossa empresa de hospedagem oferece diversas opções de planos e processadores, atendendo a diferentes necessidades e orçamentos. Com essa flexibilidade, garantimos soluções adequadas para todos os nossos clientes.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-up-long fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
              Uptime de 99,7%
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Nossa empresa de hospedagem garante um uptime de 99,7% em todos os nossos serviços. Isso assegura alta disponibilidade e confiança para os seus projetos online.
              Conte com a estabilidade que oferecemos para manter seu negócio sempre conectado.
              </p>
            </div>
          </div>

          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="mb-9 rounded-[20px] bg-white dark:bg-dark-2 p-10 shadow-2 hover:shadow-lg md:px-7 xl:px-10">
              <div className="background-icones mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl">
                <i className="fa-duotone fa-solid fa-user-check fa-2xl" style={{ color: 'white' }}></i>
              </div>
              <h4 className="textopreto dark:text-white mb-[14px] text-2xl font-semibold">
              Suporte de qualidade
              </h4>
              <p className="text-body-color dark:text-dark-6">
              Nossa empresa de hospedagem oferece atendimento contínuo e personalizado em todos os nossos serviços, todos os dias do ano. Estamos disponíveis para garantir que suas operações permaneçam ininterruptas e bem suportadas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
