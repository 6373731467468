import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative bg-white dark:bg-dark pt-[120px] pb-[110px] lg:pt-[150px] meu-elemento-hero">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full px-4 lg:w-5/12">
            <div className="hero-content">
              <h1 className="mb-5 text-5xl leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40x] xl:text-4xl"
                style={{ fontWeight: 700, color: '#2F1C6A' }}>
                Tudo o que você precisa para Hospedar seus serviços!
              </h1>
              <p className="mb-8 max-w-[480px] text-base text-body-color dark:text-dark-6">
              Nossa empresa de hospedagem possui um data center em São Paulo, oferecendo alta disponibilidade e segurança.
              Contamos com proteção anti-DDoS de ponta para garantir a integridade dos nossos serviços. Além disso, possuimos infraestrutura própria, assegurando desempenho otimizado e confiabilidade.
              </p>
              <p style={{ color: '#2F1C6A', marginBottom: 30 }}>
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Proteção anti-ddos Inline <br />
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Backups inclusos <br />
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Performance além da imaginação
              </p>
              <ul className="flex flex-wrap items-center">
                <li>
                  <a href="#"
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-center text-white rounded-md btn-primario lg:px-7">
                    Ver Planos
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hidden px-4 lg:block lg:w-1/12"></div>
          <div className="w-full px-4 lg:w-6/12">
            <div className="lg:ml-auto lg:text-right">
              <div className="relative z-10 inline-block pt-11 lg:pt-0">
                <img src="" alt="" className="max-w-full lg:ml-auto" />
                {/* Add any SVG or additional design elements */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
