import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import Navbar from './components/index/Navbar.jsx';
import HeroSection from './components/index/HeroSection.jsx';
import ServicesSection from './components/index/ServicesSection.jsx';
import AboutSection from './components/index/AboutSection.jsx';
import FAQSection from './components/index/FAQSection.jsx';
import Footer from './components/index/Footer.jsx';
import './assets/css/core.css';
import './assets/css/tailwind.css';

const App = () => {
  useEffect(() => {
    ScrollReveal().reveal('.meu-elemento-navbar', { origin: 'top', distance: '50px', duration: 1000, delay: 200 });
    ScrollReveal().reveal('.meu-elemento-hero', { origin: 'left', distance: '100px', duration: 2000, delay: 200 });
    ScrollReveal().reveal('.meu-elemento-services', { origin: 'right', distance: '100px', duration: 2000, delay: 200 });
    ScrollReveal().reveal('.meu-elemento-about', { origin: 'bottom', distance: '100px', duration: 2000, delay: 200 });
    ScrollReveal().reveal('.meu-elemento-faq', { origin: 'top', distance: '100px', duration: 2000, delay: 200 });
    ScrollReveal().reveal('.meu-elemento-footer', { origin: 'bottom', distance: '50px', duration: 1000, delay: 200 });
  }, []);

  return (
    <>
      <HeroSection />
      <ServicesSection />
      <AboutSection />
      <FAQSection />
      <Footer />
    </>
  );
};

export default App;
