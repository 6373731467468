import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './components/index/Footer.jsx';

const PageNotFound = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <div className="-mx-4 flex flex-wrap" data-id="ad1d9fc2-aa91-4e61-a" style={{marginTop: "250px", marginBottom: "120px"}}>
                <div className="w-full px-4 selected" data-id="5d819f80-c039-4377-a">
                    <div
                        className="mx-auto mb-[60px] max-w-[510px] text-center"
                        data-id="5780492e-9c37-4fc3-8"
                    >
                        <span
                            className="mb-2 block text-lg font-semibold text-primary"
                            content-id="0d5991b5-e73b-459e-9"
                            data-id="5d3621cf-4eb1-45a2-b"
                        >
                            Página não encontrada!
                        </span>
                        <h2
                            className="mb-3 text-3xl leading-[1.208] font-bold textopreto sm:text-4xl md:text-[40px]"
                            content-id="fbe17dee-4bb0-4f20-9"
                            data-id="4f4da58c-adec-4698-b"
                        >
                            ERROR 404
                        </h2>
                        <p
                            className="text-base text-body-color dark:text-dark-6"
                            content-id="dd613355-b4f8-47fa-a"
                            data-id="165ba753-a88a-4fd7-b"
                        >
                            A pagina não está funcionando ou não foi encontrada.<br />Volte para pagina inicial!
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PageNotFound;
