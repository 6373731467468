import React from "react";

const BlockVPS = () => {
    const planData = [
        {
            id: 1,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "1vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "4GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "32GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$59.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/vps-br-01",
            estoque: true
        },
        {
            id: 2,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "2vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "6GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "48GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$89.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-02",
            estoque: true
        },
        {
            id: 3,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "3vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "8GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "64GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$119.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-03-1",
            estoque: true
        },
        {
            id: 4,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "4vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "12GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "96GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$179.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-04",
            estoque: true
        },
        {
            id: 5,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "6vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "16GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "128GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$239.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-05",
            estoque: true
        },
        {
            id: 6,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "7vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "24GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "192GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "1", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$359.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-06",
            estoque: true
        },
        {
            id: 7,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "8vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "32GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "256GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "2", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$479.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-07",
            estoque: true
        },
        {
            id: 8,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "10vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "48GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "512GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "3", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$671.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-08-1",
            estoque: true
        },
        {
            id: 9,
            name: "AMD Ryzen 9 7950x",
            image: "https://imgs.search.brave.com/SspHh6DNhnAp3K7AGQPF-3PCp6xIOzEkaqhr62fAj9E/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/d29ybGR2ZWN0b3Js/b2dvLmNvbS9sb2dv/cy9hbWQtbG9nby1w/cmUtMjAxMy5zdmc",
            specs: [
                { icon: "fas fa-microchip", label: "12vCPU", detail: "5.6Ghz" },
                { icon: "fas fa-memory", label: "64GB", detail: "DDR5" },
                { icon: "fas fa-network-wired", label: "1000Mbit/s", detail: "REDE" },
                { icon: "fas fa-hdd", label: "768GB", detail: "SSD" },
                { icon: "fas fa-globe", label: "5", detail: "Endereço IP" },
                { icon: "fas fa-hdd", label: "280Tbps", detail: "Anti-ddos" }
            ],
            price: "R$831.99",
            link: "https://financeiro.magnohost.com.br/store/servidores-vps-br-ryzen-9/servidor-vps-br-09",
            estoque: true
        }
    ];

    return (
        <section
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: 0,
                padding: 20,
                marginTop: 180
            }}
        >
            <div className="-mx-4 flex flex-wrap" data-id="ad1d9fc2-aa91-4e61-a">
                <div className="w-full px-4 selected" data-id="5d819f80-c039-4377-a">
                    <div
                        className="mx-auto mb-[60px] max-w-[510px] text-center"
                        data-id="5780492e-9c37-4fc3-8"
                    >
                        <span
                            className="mb-2 block text-lg font-semibold text-primary"
                            content-id="0d5991b5-e73b-459e-9"
                            data-id="5d3621cf-4eb1-45a2-b"
                        >
                            Escolha a sua vps com otimo desempenho!
                        </span>
                        <h2
                            className="mb-3 text-3xl leading-[1.208] font-bold textopreto sm:text-4xl md:text-[40px]"
                            content-id="fbe17dee-4bb0-4f20-9"
                            data-id="4f4da58c-adec-4698-b"
                        >
                            Hospedagem de VPS!
                        </h2>
                        <p
                            className="text-base text-body-color dark:text-dark-6"
                            content-id="dd613355-b4f8-47fa-a"
                            data-id="165ba753-a88a-4fd7-b"
                        >
                            Nossos servidores VPS proporcionam desempenho superior e escalabilidade excepcional para suas necessidades de hospedagem. Experimente a confiabilidade e flexibilidade únicas da MagnoHost.
                        </p>
                    </div>
                </div>
            </div>

            <div className="card-container">
                {planData.map((plan) => (
                    <div className="card" key={plan.id}>
                        <span
                            className={`status ${plan.estoque ? "status-on" : "status-off"}`}
                            style={{
                                backgroundColor: plan.estoque ? "#7a0cf3" : "#d9534f",
                                color: "white"
                            }}
                        >
                            {plan.estoque ? "EM ESTOQUE" : "FORA DE ESTOQUE"}
                        </span>
                        <div className="title">
                            <img
                                src={plan.image}
                                style={{ width: 130 }}
                                alt={plan.name}
                                className="logo"
                            />
                            <h2>{plan.name}</h2>
                        </div>
                        <div className="specs">
                            {plan.specs.map((spec, index) => (
                                <div className="spec-item" key={index}>
                                    <i className={spec.icon} style={{ color: "#2f1c6a"}}></i>
                                    <p style={{ color: "#2f1c6a" }}>
                                        <strong>{spec.label}</strong>
                                    </p>
                                    <p style={{ color: "#999999" }}>{spec.detail}</p>
                                </div>
                            ))}
                        </div>
                        <div className="footer">
                            <p className="price">
                                {plan.price}
                                <span style={{ color: "#999999", fontWeight: 400, fontSize: 14 }}>
                                    /Mês
                                </span>
                            </p>
                            <a href={plan.link} className="button">
                                Contratar
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default BlockVPS;
