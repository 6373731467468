import React from 'react';
import Footer from './components/index/Footer.jsx'; 
import AboutSection from './components/minecraft/AboutSection.jsx'; 
import HeroSection from './components/minecraft/HeroSectionMine.jsx';
import PlansSection from './components/minecraft/PlansSection.jsx';
import FAQSection from './components/minecraft/FAQSectionMINE.jsx';
import TestFreeMinecraft from './components/minecraft/TestFreeMinecraft.jsx';

const MinecraftPage = () => {
  return (
    <div>
      <HeroSection />
      <PlansSection />
      <TestFreeMinecraft />
      <AboutSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default MinecraftPage;
