import React from 'react';

const HeroSectionM = () => {
  return (
    <div className="relative bg-white dark:bg-dark pt-[120px] pb-[110px] lg:pt-[150px] meu-elemento-hero">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full px-4 lg:w-5/12" style={{ marginTop: 90 }}>
            <div className="hero-content">
              <h1 className="mb-5 text-5xl leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40x] xl:text-4xl"
                style={{ fontWeight: 700, color: '#2F1C6A' }}>
                A Melhor Hospedagem minecraft do Brasil
              </h1>
              <p className="mb-8 max-w-[480px] text-base text-body-color dark:text-dark-6">
              Junte-se à MagnoHost e experimente o melhor em suporte e soluções para sua aplicação. Oferecemos serviços flexíveis e escaláveis que atendem desde as menores até as maiores demandas, sempre com valores acessíveis e que se ajustam ao seu orçamento.
              </p>
              <p style={{ color: '#2F1C6A', marginBottom: 30 }}>
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Proteção Anti-DDos Inline <br />
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Backups off-site <br />
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Planos flexiveis <br />
                <i className="fa-duotone fa-solid fa-circle-check" style={{ '--fa-primary-color': '#2F1C6A', '--fa-secondary-color': '#00ff88' }}></i> Teste grátis de 24h
              </p>
            </div>
          </div>
          <div className="hidden px-4 lg:block lg:w-1/12"></div>
          <div className="w-full px-4 lg:w-6/12">
            <div className="lg:ml-auto lg:text-right">
              <div className="relative z-10 inline-block pt-11 lg:pt-0">
                  <img src="#" alt="" className="max-w-full lg:ml-auto" style={{ width: "500px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionM;
