import React from 'react';
import Footer from './components/index/Footer.jsx'; 
import PlansSection from './components/dedicado/Dedicadoblock.jsx';
import AboutSection from './components/dedicado/AboutDedicado.jsx'; 
import FAQSection from './components/dedicado/FAQSection.jsx';

const DedicadoPagina = () => {
  return (
    <div>
      <PlansSection />
      <AboutSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default DedicadoPagina;
