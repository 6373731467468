import React from 'react';

const Terms = () => {
  return (
    <>
  <div
    className="container"
    style={{
      marginTop: 280,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <h2 style={{ color: "#7a0cf2", fontSize: 36, fontWeight: 700 }}>
      Termos de Serviço da MagnoHost
    </h2>
    <p>
      Ao contratar um de nossos serviços você concorda com os termos de serviço.
    </p>
  </div>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: 120 }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Termos de Serviço:
        </h2>
      </div>
      <p>
        <br />
        <strong>1. Aceitação dos Termos</strong><br></br>
        Ao utilizar os serviços da Magno Host, você concorda com estes Termos de
        Serviço. Se você não concordar com estes termos, por favor, não utilize
        de nossos serviços.
        <br />
        <br />
        <strong>2. Registro e Segurança</strong><br></br>
        Como parte do processo de registro, você criará uma senha e uma conta.
        Você é responsável por manter a confidencialidade da senha e da conta e
        é totalmente responsável por todas as atividades que ocorrem sob sua
        senha ou conta.
        <br />
        <br />
        <strong>3. Uso do Serviço</strong><br></br>
        Você concorda em usar nossos serviços de maneira legal e não utilizar
        nossos serviços para atividades ilegais ou maliciosas.
        <br />
        <br />
        <strong>4. Pagamento</strong><br></br>
        Os detalhes sobre preços e planos de pagamento estão disponíveis em
        nosso site. O pagamento é exigido antecipadamente para todos os serviços
        e o tempo de aprovação pode variar de acordo com a forma de pagamento
        escolhida. Consulte mais informações sobre ao adquirir um produto.
        <br />
        <br />
        <strong>5. Uptime SLA</strong><br></br>
        Nós da MagnoHost trabalhamos garantindo a você 99,97% de uptime em todos
        os nossos serviços e compensamos toda e qualquer queda nos mesmos com
        até 5x o tempo em seus serviços, caso o downtime seja superior a 0,03%
        do tempo total de serviço mensal.
        <br />
        <br />
        <strong>6. Limitação de Responsabilidade</strong><br></br>
        A Magno Host não será responsável por quaisquer danos diretos,
        indiretos, incidentais, especiais, consequenciais ou exemplares
        resultantes do uso ou da incapacidade de usar nossos serviços.
        <br />
        <br />
        <strong>7. Indenização</strong><br></br>
        Você concorda em indenizar e isentar a Magno Host de qualquer
        reivindicação ou demanda feita por terceiros devido ao seu uso do
        serviço.
        <br />
        <br />
        <strong>8. Resolução de Disputas</strong><br></br>
        Qualquer disputa relacionada a estes Termos de Serviço será regida pelas
        leis do Brasil sem levar em conta sua escolha de disposições legais.
        <br />
        <br />
        <strong>9. Alterações nos Termos</strong><br></br>
        A Magno Host reserva-se o direito de alterar estes Termos de Serviço a
        qualquer momento. As alterações entrarão em vigor imediatamente após a
        publicação em nosso site, fique de olho regularmente para não ser
        prejudicado por quaisquer mudanças.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Política de Privacidade:
        </h2>
      </div>
      <p>
        <br />
        <strong>1.0 Informações que coletamos</strong><br></br>
        Podemos coletar informações pessoais que você nos fornece ao se
        registrar, como seu nome, endereço de e-mail, detalhes de pagamento e
        afins.
        <br />
        <br />
        <strong>1.1 Como usamos suas informações</strong><br></br>
        Usamos suas informações para fornecer e melhorar nossos serviços,
        processar pagamentos e comunicar-nos com você.
        <br />
        <br />
        <strong>1.2 Compartilhamento de informações</strong><br></br>
        Não compartilhamos suas informações pessoais com terceiros, exceto
        conforme necessário para fornecer nossos serviços ou conforme exigido
        por lei.
        <br />
        <br />
        <strong>1.3 Segurança</strong><br></br>
        Estamos comprometidos em proteger suas informações. Implementamos
        medidas de segurança para proteger suas informações de acesso, uso ou
        divulgação não autorizados.
        <br />
        <br />
        <strong>1.4 Cookies</strong><br></br>
        Usamos cookies para melhorar a sua experiência em nosso site. Você pode
        optar por desativar os cookies, mas isso pode afetar a funcionalidade do
        nosso site.
        <br />
        <br />
        <strong>1.5 Alterações na Política de Privacidade</strong><br></br>
        Podemos atualizar nossa Política de Privacidade de tempos em tempos.
        Recomendamos que você revise esta Política regularmente para quaisquer
        alterações.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Política de Reembolso:
        </h2>
      </div>
      <p>
        <br />
        <strong>1.0 Cancelamento</strong><br></br>
        Você pode cancelar sua conta a qualquer momento em sua área do cliente,
        sem demais complicações.
        <br />
        <br />
        1.1 <strong>Reembolso</strong><br></br>
        Para reembolsos existe um prazo legal de cancelamento de até 7 dias após
        a contratação de um de nossos serviços, como estipulado no Art. 49 do
        Código de defesa do consumidor.
        <br />
        <br />
        <strong>1.2 Processamento de reembolsos</strong><br></br>
        Após a solicitação de reembolso que deverá ser executada via ticket na
        área do cliente, contendo todas as informações necessárias como motivos
        e dados bancários para devolução dos valores. A Magno Host se compromete
        a dar inicio ao reembolso em até 72 horas e finalizar o mesmo em até no
        máximo 10 dias corridos.
        <br />
      </p>
    </div>
  </section>
</>
  );
};

export default Terms;
